import React, { useState } from 'react';
import { getAuth, confirmPasswordReset } from 'firebase/auth';
import { useLocation } from 'react-router-dom';
import '../css/resetpasswordfirebase.css'
import { Helmet } from 'react-helmet';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [newPassword1, setNewPassword1] = useState('');
  const [message, setMessage] = useState('');
  const [color, setColor] = useState('black');
  const location = useLocation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const urlParams = new URLSearchParams(location.search);
    const oobCode = urlParams.get('oobCode');

    if (!oobCode) {
      setMessage('Invalido o la pagina a expirado.');
      setColor('red')
      return;
    }

    const auth = getAuth();
    if(newPassword!==newPassword1){
        setMessage('Las contraseñas son diferentes')
        setColor('red')
    }
    else{
    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
      setMessage('La contraseña se a cambiado correctamente, cierre la pestaña.');
      setColor('green');
    } catch (error) {
      setMessage('Error al enviar la nueva contraseña');
      setColor('red')
    }}
  };

  return (
    <div className="reset-password-container">
      <title>Restablecer contraseña</title>
      <form onSubmit={handleResetPassword}>
        <h1>Restablecer contraseña</h1>
        <div className="password-input-container">
          <input
            type={showPassword ? 'text' : 'password'}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="Nueva contraseña"
            required
          />
          <FontAwesomeIcon
            icon={showPassword ? faEyeSlash : faEye}
            onClick={() => setShowPassword(!showPassword)}
            className="toggle-password-visibility"
          />
        </div>
        <div className="password-input-container">
          <input
            type={showPassword ? 'text' : 'password'}
            value={newPassword1}
            onChange={(e) => setNewPassword1(e.target.value)}
            placeholder="Nueva contraseña"
            required
          />
          <FontAwesomeIcon
            icon={showPassword ? faEyeSlash : faEye}
            onClick={() => setShowPassword(!showPassword)}
            className="toggle-password-visibility"
          />
        </div>
        <button type="submit">Restablecer contraseña</button>
        {message && <div style={{ color }} id="message">{message}</div>}
      </form>
    </div>
  );
};

export default ResetPassword;