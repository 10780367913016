// Importa los módulos necesarios
import React from "react";
import "./person.css"; // Asegúrate de crear un archivo CSS para este componente

// Definición del componente Person
const Person = ({ name, position, description, image, customClass }) => {
  return (
    <div className={`people ${customClass}`}>
      <div className="info">
        <h1>{name}</h1>
        <h2>Cargo: {position}</h2>
        <p>{description}</p>
      </div>
      <img src={image} alt={`${name}`} className="profile-img" />
    </div>
  );
};

// Exporta el componente Person
export default Person;
