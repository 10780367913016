import React, { useState, useEffect } from "react";
import { auth } from "../../../../firebase/credenciales"; // Asegúrate de importar la instancia de autenticación desde tu archivo firebase.js
import "../quimicos.css";
import { Link } from "react-router-dom";

import Fondo from '../../img/Regencia.jpg'
import ProductosQuimicos from "../componenteQuimico.jsx";



const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setUser(authUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const descriptions = [
    { text: "Dirigido a empresas que requieren un control más detallado de productos químicos, incluyendo registros y etiquetado.", customClass: "" },
    { text: <strong>• 6 horas de regencia mensual</strong>, strongText: "", totalText:" ($115/hora): $690", customClass: "textClass-Quimica" },
    { text: <strong>• Registro de productos químicos (incluye materias primas):</strong>, strongText: "", totalText:" $100", customClass: "textClass-Quimica" },
    { text: <strong>• Confección de etiqueta según SGA:</strong>, strongText: "", totalText: " $75", customClass: "textClass-Quimica" },
    { text: <strong>• Asesoría adicional</strong>, strongText: "", totalText: " (2 horas): $100", customClass: "textClass-Quimica" },
    { text: <strong>• Total paquete:  $965</strong>, strongText: "", totalText: "", customClass: "textClass-Quimica" },
    { text: <strong></strong>, strongText: "", totalText: "Notas:", customClass: "texto-notas" },
    { text: <strong></strong>, strongText: "", totalText: " Esto es una sugerencia de paquete, podemos cotizar un servicio que se adapte a sus necesitas y que se adecue a su presupuesto", customClass: "texto-notas" },
    { text: <strong></strong>, strongText: "", totalText: "Precio no incluye inscripción de la empresa al colegio respectivo ni la apertura de bitácora de regencia ante el colegio", customClass: "texto-notas" }
  ];

  return (
    <ProductosQuimicos 
      backgroundImage={Fondo} 
      title="Regencia para Industrias Químicas" 
      buttonText="Regencia" 
      descriptions={descriptions}
      redirectTo="/regencia"
      imgClass="imgClass-RBM"
    />
  );
};

export default App;
