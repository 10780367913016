import React, { useState, useEffect } from "react";
import { auth } from "../../../../firebase/credenciales"; // Asegúrate de importar la instancia de autenticación desde tu archivo firebase.js
import "../quimicos.css";
import { Link } from "react-router-dom";

import Fondo from '../../img/BPM.jpg'
import ProductosQuimicos from "../componenteQuimico.jsx";



const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setUser(authUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const descriptions = [
    { text: "Este paquete está destinado a empresas que necesitan realizar auditorías periódicas para mantener sus certificaciones BPM y cumplir con los estándares exigidos por las autoridades sanitarias.", customClass: "" },
    { text: <strong>• Auditoría de BPM completa</strong>, strongText: "", totalText:" (2 visitas, $115/hora, 8 horas en total): $920", customClass: "textClass-Quimica" },
    { text: <strong>• Revisión de documentación técnica y operativa:</strong>, strongText: "", totalText:" $150", customClass: "textClass-Quimica" },
    { text: <strong>• Informe y recomendaciones de revalidación de BPM:</strong>, strongText: "", totalText: " $250", customClass: "textClass-Quimica" },
    { text: <strong>• Capacitación del personal en procesos actualizados de BPM</strong>, strongText: "", totalText: "(4 horas a $50/hora): $200", customClass: "textClass-Quimica" },
    { text: <strong>• Total paquete: $1,520</strong>, strongText: "", totalText: "", customClass: "textClass-Quimica" },
    { text: <strong></strong>, strongText: "", totalText: "Nota: Esto es una sugerencia de paquete, podemos cotizar un servicio que se adapte a sus necesitas y que se adecue a su presupuesto", customClass: "texto-notas" }
  ];

  return (
    <ProductosQuimicos 
      backgroundImage={Fondo} 
      title="Auditoría y Revalidación de BPM" 
      buttonText="Regencia" 
      descriptions={descriptions}
      redirectTo="/BPM"
      imgClass="imgClass-BPM"
    />
  );
};


export default App;
