import React, { useState, useEffect } from "react";
import { auth } from "../../../../firebase/credenciales"; // Asegúrate de importar la instancia de autenticación desde tu archivo firebase.js
import "../quimicos.css";
import { Link } from "react-router-dom";

import Fondo from '../../img/BPM.jpg'
import ProductosQuimicos from "../componenteQuimico.jsx";



const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setUser(authUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const descriptions = [
    { text: "Un paquete centrado en la gestión de residuos peligrosos y el cumplimiento de las BPM en temas ambientales, que es parte de la normativa para muchas industrias.", customClass: "" },
    { text: <strong>• Elaboración de ficha de emergencia para transporte de residuos peligrosos:</strong>, strongText: "", totalText:" $125", customClass: "textClass-Quimica" },
    { text: <strong>• Elaboración de plan de gestión de residuos peligrosos</strong>, strongText: "", totalText:" (5 horas a $50/hora): $250", customClass: "textClass-Quimica" },
    { text: <strong>• Auditoría de cumplimiento ambiental</strong>, strongText: "", totalText: " (4 horas a $115/hora): $460", customClass: "textClass-Quimica" },
    { text: <strong>• Reporte de operación de residuos peligrosos:</strong>, strongText: "", totalText: " $150", customClass: "textClass-Quimica" },
    { text: <strong>• Total paquete: $985</strong>, strongText: "", totalText: "", customClass: "textClass-Quimica" },
    { text: <strong></strong>, strongText: "", totalText: "Nota: Esto es una sugerencia de paquete, podemos cotizar un servicio que se adapte a sus necesitas y que se adecue a su presupuesto", customClass: "texto-notas" }
  ];

  return (
    <ProductosQuimicos 
      backgroundImage={Fondo} 
      title="Gestión Integral de Residuos y Seguridad Ambiental" 
      buttonText="Regencia" 
      descriptions={descriptions}
      redirectTo="/BPM"
      imgClass="imgClass-BPM"
    />
  );
};


export default App;
