import React, { useState, useEffect } from "react";
import { auth } from "../../../firebase/credenciales"; // Asegúrate de importar la instancia de autenticación
import NavbarPro from "../Components/NavBarPro";
import Navbar from "../Components/NavBarLite";
import { Link } from "react-router-dom";
import Boton from '../Components/Boton';
import { ReactComponent as SVGregresar } from "../icon/newIcon/Regresar.svg"; // Importa el SVG como componente
import "./quimicos.css";

import BotonCorreo from "../Components/BotonCorreo.jsx";

const ServicioCard = ({ to = "/servicios", svg: SvgIcon, title, customClass }) => {
  return (
    <div className={`box-servicio ${customClass}`}>
      <Link to={to} className="link-servicio">
        <SvgIcon className="svg-Servicio" />
        <p className="hola">{title}</p>
      </Link>
    </div>
  );
};

const ProductosQuimicos = ({ 
  backgroundImage, 
  title, 
  buttonText, 
  descriptions, // Cambiado para aceptar múltiples descripciones
  containerClass, 
  imgClass, 
  titleClass, 
  textClass, 
  redirectTo = "/servicios" // Parámetro de entrada para la ruta
}) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setUser(authUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className={`body-productosquimicos ${containerClass}`}>
      {user ? <NavbarPro /> : <Navbar />}
      <div className={`contenedor-productosquimicos ${containerClass}`}>
        <div className={`img-productosquimicos ${imgClass}`}>
          <img src={backgroundImage} alt="Fondo productos químicos" className={`fondo ${imgClass}`} />
          <h1 className={`${titleClass}`}>{title}</h1>
        </div>

        <ServicioCard
          to={redirectTo} // Usar el parámetro redirectTo
          svg={SVGregresar}
          title={buttonText}
          customClass={containerClass} // Puedes pasar la clase personalizada aquí si deseas
        />

        {/* Renderiza las descripciones */}
        {descriptions.map((desc, index) => (
          <p key={index} className={`texto-quimico ${desc.customClass}`}>
            {desc.text}
            {desc.strongText && <strong>{desc.strongText}</strong>}
            {desc.totalText}
          </p>
        ))}
        <BotonCorreo buttonText='Cotizar servicio'></BotonCorreo>
      </div>
      <Boton />
    </div>
  );
};

export default ProductosQuimicos;
