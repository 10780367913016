import React, { useState, useEffect } from "react";
import { auth } from "../../../../firebase/credenciales"; // Asegúrate de importar la instancia de autenticación desde tu archivo firebase.js
import "../quimicos.css";
import { Link } from "react-router-dom";

import Fondo from '../../img/Regencia.jpg'
import ProductosQuimicos from "../componenteQuimico.jsx";



const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setUser(authUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const descriptions = [
    { text: "Ideal para nuevas empresas que necesitan establecerse legalmente con registros y procesos adecuados.", customClass: "" },
    { text: <strong>• 10 horas de regencia mensual</strong>, strongText: "", totalText:" ($115/hora): $1,150", customClass: "textClass-Quimica" },
    { text: <strong>• Inscripción de productos químicos:</strong>, strongText: "", totalText:" $100", customClass: "textClass-Quimica" },
    { text: <strong>• Elaboración de ficha de emergencia para transporte de productos peligrosos:</strong>, strongText: "", totalText: " $120", customClass: "textClass-Quimica" },
    { text: <strong>• Total paquete: $1,370</strong>, strongText: "", totalText: "", customClass: "textClass-Quimica" },
    { text: <strong></strong>, strongText: "", totalText: "Notas:", customClass: "texto-notas" },
    { text: <strong></strong>, strongText: "", totalText: " Esto es una sugerencia de paquete, podemos cotizar un servicio que se adapte a sus necesitas y que se adecue a su presupuesto", customClass: "texto-notas" },
    { text: <strong></strong>, strongText: "", totalText: "Precio no incluye inscripción de la empresa al colegio respectivo ni la apertura de bitácora de regencia ante el colegio", customClass: "texto-notas" }
  ];

  return (
    <ProductosQuimicos 
      backgroundImage={Fondo} 
      title="Consultoría y Regencia para Nuevas Empresas" 
      buttonText="Regencia" 
      descriptions={descriptions}
      redirectTo="/regencia"
      imgClass="imgClass-RBM"
    />
  );
};


export default App;
