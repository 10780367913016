
import React from "react";
import NavbarPro from "./Components/NavBarPro";
import Boton from './Components/Boton'

function HomePro() {
  return (
    <div>
      <NavbarPro />
      <Boton></Boton>
    </div>
  );
}

// Exporta el componente Home
export default HomePro;
