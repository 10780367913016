import {ref, uploadBytes, uploadString } from "firebase/storage";
import {storage, auth} from "../firebase/credenciales"

const uploadFiles = async (
  empresa,
  producto,
  archivos,
  tipoProducto,
  etiquetaChecked,
  fichaSeguridadChecked,
  otrosServiciosChecked,
  otrosServiciosText,
  hojaSeguridadChecked,
  traduccionDocumentoChecked,
  setUploadProgress
) => {
  try {
    if (!Array.isArray(archivos)) {
      console.error('El parámetro "archivos" no es un array.');
      return;
    }

    // Cambié la referencia para que los archivos se carguen en la carpeta "Documentos"
    const empresaProductoRef = ref(storage, `Documentos/${empresa}/${producto}`);

    const uploads = archivos.map(async (archivo) => {
      const fileRef = ref(empresaProductoRef, archivo.name);

      try {
        const uploadTaskSnapshot = await uploadBytes(fileRef, archivo);

        // Espera a que termine la carga
        await uploadTaskSnapshot;

        const progress = (uploadTaskSnapshot.bytesTransferred / uploadTaskSnapshot.totalBytes) * 100;

        // Verifica si progress es un número antes de asignarlo a setUploadProgress
        if (!isNaN(progress)) {
          setUploadProgress(progress);
        }

      } catch (error) {
        console.error("Error en la carga del archivo:", error && error.message);
      }
    });

    // Espera a que todas las cargas se completen
    await Promise.all(uploads);

    const user = auth.currentUser;
    const userEmail = user ? user.email : "Correo no disponible";

    const datosProducto = {
      tipoProducto: tipoProducto || "No especificado",
      etiquetaChecked: etiquetaChecked || false,
      fichaSeguridadChecked: fichaSeguridadChecked || false,
      otrosServiciosChecked: otrosServiciosChecked || false,
      hojaSeguridadChecked: hojaSeguridadChecked || false,
      traduccionDocumentoChecked: traduccionDocumentoChecked || false,
      otrosServiciosText: otrosServiciosText || "",
      userEmail,
      Empresa: empresa,
      Producto: producto,
    };

    // Convertir a cadena JSON con formato y sangrías
    const textoArchivo = `Tipo de Producto: ${datosProducto.tipoProducto}
    Etiqueta: ${datosProducto.etiquetaChecked}
    Ficha de Seguridad: ${datosProducto.fichaSeguridadChecked}
    Otros Servicios: ${datosProducto.otrosServiciosChecked ? datosProducto.otrosServiciosText : 'No'}
    Hoja de seguridad: ${datosProducto.otrosServiciosChecked}
    Traduccion del documento: ${datosProducto.hojaSeguridadChecked}
    Usuario: ${datosProducto.userEmail}
    Empresa: ${datosProducto.Empresa}
    Producto: ${datosProducto.Producto}`;

    const textoRef = ref(empresaProductoRef, "datos.txt");
    await uploadString(textoRef, textoArchivo);

    console.log("Archivos subidos correctamente, incluido el archivo de texto con el correo del cliente.");
  } catch (error) {
    console.error("Error al subir archivos:", error && error.message);
  }
};

export default uploadFiles;
