import React, { useEffect, useState, useRef } from "react";
import NavbarPro from "./Components/NavBarLite.jsx";
import "../css/uploadFile.css";
import { auth } from "../../firebase/credenciales.js";
import uploadFiles from "../../functions/firebaseService.js";
import { Link, useNavigate } from "react-router-dom";
import enviarDatosAFirestore from "../../functions/uploadData.js";
import BotonCorreo from "./Components/BotonCorreo.jsx";
function Upload() {
  const archivosInputRef = useRef(null);
  const [userEmail, setUserEmail] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [precioBase, setPrecioBase] = useState(0);
  const [precioEtiqueta, setprecioEtiqueta] = useState(0);
  const [precioFicha, setprecioFicha] = useState(0);
  const [precioHoja, setprecioHoja] = useState(0);
  const [traduccionDocumento, settraduccionDocumento] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [isEditable, setIsEditable] = useState(false);

  // Estado para nuevos servicios
  const [regenciaChecked, setRegenciaChecked] = useState(false);
  const [horasRegencia, setHorasRegencia] = useState(null);
  const [asesoriaChecked, setAsesoriaChecked] = useState(false);
  const [horasAsesoria, setHorasAsesoria] = useState(null);


  const [formValues, setFormValues] = useState({
    tipoProducto: "Productos Químicos",
    etiquetaChecked: false,
    fichaSeguridadChecked: false,
    otrosServiciosChecked: false,
    hojaSeguridadChecked: false,
    traduccionDocumentoChecked: false,
    otrosServiciosText: "",
    empresa: "",
    producto: "",
  });

  const handleEditableChange = (event) => {
    setIsEditable(event.target.checked);
  };

  useEffect(() => {
    const calcularPrecio = () => {
      let precioBase = 0;
  
      // Determina el precio base según el tipo de producto
      switch (formValues.tipoProducto) {
        case "Productos Químicos":
          precioBase = 170;
          break;
        case "Productos Higiénicos":
          precioBase = 315;
          break;
        case "Registro de plaguicidas":
          precioBase = 430;
          break;
        case "Registro de alimentos":
          precioBase = 275;
          break;
        case "Registro de cosméticos":
          precioBase = 430;
          break;
        default:
          break;
      }
  
      // Si no es editable, deja el precio base en 0
      if (!isEditable) {
        precioBase = 0.0;
      }
  
      // Calcula precios de servicios adicionales
      const precioEtiqueta = formValues.etiquetaChecked ? 75 : 0; 
      const precioFicha = formValues.fichaSeguridadChecked ? 0 : 0;
      const precioHoja = formValues.hojaSeguridadChecked ? 120 : 0;
      const traduccionDocumento = formValues.traduccionDocumentoChecked ? 50 : 0;
  
      const precioRegencia = regenciaChecked ? horasRegencia * 115 : 0;
      const precioAsesoria = asesoriaChecked ? horasAsesoria * 50 : 0;
  
      // Suma total
      const total = precioBase + precioEtiqueta + precioFicha + precioHoja + traduccionDocumento + precioRegencia + precioAsesoria;
  
      // Actualiza los estados de precio
      setTotalPrice(total);
      setPrecioBase(precioBase);
      setprecioEtiqueta(precioEtiqueta);
      setprecioFicha(precioFicha);
      setprecioHoja(precioHoja);
      settraduccionDocumento(traduccionDocumento);
    };
  
    calcularPrecio();
  }, [formValues, isEditable, regenciaChecked, horasRegencia, asesoriaChecked, horasAsesoria]);

  const handleInputChange = (event) => {
    const { name, type, checked, value } = event.target;
    const inputValue = type === "checkbox" ? checked : value;

    setFormValues({
      ...formValues,
      [name]: inputValue,
    });
  };

  const handleButtonClick = async () => {
    const user = auth.currentUser;
    if (user) {
      setUserEmail(user.email);
  
      const archivosInput = archivosInputRef.current;
  
      if (archivosInput && archivosInput.files && archivosInput.files.length > 0) {
        const archivos = Array.from(archivosInput.files);
  
        try {
          setUploading(true);
  
          await uploadFiles(
            formValues.empresa,
            formValues.producto,
            archivos,
            formValues.tipoProducto,
            formValues.etiquetaChecked,
            formValues.fichaSeguridadChecked,
            formValues.otrosServiciosChecked,
            formValues.otrosServiciosText,
            formValues.hojaSeguridadChecked,
            formValues.traduccionDocumentoChecked,
            setUploadProgress
          );
  
          await enviarDatosAFirestore(
            formValues.producto,
            user.email,
            totalPrice,
            formValues.empresa  // Asegúrate de que formValues.empresa tenga un valor definido
          );
  
          setShowPopup(true);
        } catch (error) {
          console.error("Error al enviar datos:", error);
        } finally {
          setUploading(false);
        }
      } else {
        console.log("No hay archivos seleccionados o archivosInput no está definido.");
      }
    } else {
      console.log("No hay usuario autenticado.");
    }
  };

  useEffect(() => {
    document.body.classList.add("body-uploadFile");
    setShowDisclaimer(true);
    return () => {
      document.body.classList.remove("body-uploadFile");
      setShowDisclaimer(true);
    };
  }, []);

  const handleNuevoTramiteClick = () => {
    setShowPopup(false);
  };

  const handleDisclaimerClick = () => {
    setShowDisclaimer(false);
  };

  return (
    <div>
      <NavbarPro />
      <div className="container">
        <div className="column">
          <form className="FormLeft">
            <div className="contenedor-form">
              <div>
                <label htmlFor="editableCheckbox" className="etiqueta-Text">Permitir editar tipo de registro</label>
                <input
                  className="Checkbox"
                  type="checkbox"
                  id="editableCheckbox"
                  checked={isEditable}
                  onChange={handleEditableChange}
                  />
              </div>

              <span className="etiqueta-Text">Tipo de registro: </span>
              <select disabled={!isEditable} name="tipoProducto" onChange={handleInputChange} value={formValues.tipoProducto}>
                <option value="Productos Químicos">Productos químicos</option>
                <option value="Productos Higiénicos">Productos higiénicos</option>
                <option value="Registro de plaguicidas">Registro de plaguicidas</option>
                <option value="Registro de alimentos">Registro de alimentos</option>
                <option value="Registro de cosméticos">Registro de cosméticos</option>
              </select>
            </div>

            <span className="pregunta-form">¿Se ocupa que se elabore?</span>

            <div className="contenedor-form">
              <input
                className="Checkbox"
                type="checkbox"
                id="etiqueta"
                name="etiquetaChecked"
                checked={formValues.etiquetaChecked}
                onChange={handleInputChange}
              />
              <span className="etiqueta-Text">Etiqueta</span>
            </div>

            <div className="contenedor-form">
              <input
                className="Checkbox"
                type="checkbox"
                id="fichaSeguridad"
                name="fichaSeguridadChecked"
                checked={formValues.fichaSeguridadChecked}
                onChange={handleInputChange}
              />
              <span className="etiqueta-Text">Ficha de seguridad</span>
            </div>

            <div className="contenedor-form">
              <input
                className="Checkbox"
                type="checkbox"
                id="hojaSeguridad"
                name="hojaSeguridadChecked"
                checked={formValues.hojaSeguridadChecked}
                onChange={handleInputChange}
              />
              <span className="etiqueta-Text">Hoja de transporte</span>
            </div>

            <div className="contenedor-form">
              <input
                className="Checkbox"
                type="checkbox"
                id="traduccionDocumento"
                name="traduccionDocumentoChecked"
                checked={formValues.traduccionDocumentoChecked}
                onChange={handleInputChange}
              />
              <span className="etiqueta-Text">Traducción de documentos</span>
            </div>

            {/* Hora de Regencia */}
            <div className="contenedor-form">
              <input
                className="Checkbox"
                type="checkbox"
                id="regencia"
                checked={regenciaChecked}
                onChange={(e) => setRegenciaChecked(e.target.checked)}
              />
              <label htmlFor="regencia">Hora de regencia</label>
              {regenciaChecked && (
                <input
                  type="number"
                  min="0"
                  value={horasRegencia}
                  onChange={(e) => setHorasRegencia(Number(e.target.value))}
                />
              )}
            </div>

            {/* Hora de Asesoría */}
            <div className="contenedor-form">
              <input
                className="Checkbox"
                type="checkbox"
                id="asesoria"
                checked={asesoriaChecked}
                onChange={(e) => setAsesoriaChecked(e.target.checked)}
              />
              <label htmlFor="asesoria">Hora de asesoría</label>
              {asesoriaChecked && (
                <input
                  type="number"
                  min="0"
                  value={horasAsesoria}
                  onChange={(e) => setHorasAsesoria(Number(e.target.value))}
                />
              )}
            </div>
            
          </form>
        </div>

        <div className="column">
          <form className="FormRight">
            <div className="contenedor-form2">
              <h2>Subir los archivos al sistema</h2>
            </div>
            <div className="contenedor-form2">
              <span>Nombre de Empresa:</span>
              <input type="text" name="empresa" required onChange={handleInputChange} value={formValues.empresa}></input>
            </div>
            <div className="contenedor-form2">
              <span>Nombre de Producto:</span>
              <input type="text" name="producto" required onChange={handleInputChange} value={formValues.producto}></input>
            </div>
            <div className="contenedor-form2">
              <span>Archivos:</span>
              <input
                type="file"
                name="archivos[]"
                accept=".pdf, .doc, .docx, .xls, .xlsx, .jpg, .png, .gif"
                multiple
                ref={archivosInputRef}
              />
            </div>
          </form>
          <button onClick={handleButtonClick}>Enviar Datos</button>

          {uploading && (
            <div className="overlay">
              <div className="loader"></div>
            </div>
          )}

           {showPopup && (
            <div className="popup-uploadFile">
              <p>Archivos subidos</p>
              <Link to="/">Ir a home</Link>
              <Link to="/upload" onClick={handleNuevoTramiteClick}>
                Nuevo trámite
              </Link>
              <Link to="/table">Facturas</Link>
            </div>
          )}
        </div>

        {totalPrice > 0 && (
          <div className="column">
            <div className="column1">
            <p>Precio estimado</p>
            {precioBase > 0 && <p>Tipo: {precioBase}$</p>}
            {precioBase > 0 && <p>Etiqueta: {precioEtiqueta}$</p>}
            {precioHoja > 0 && <p>Precio Hoja: {precioHoja}$</p>}
            {traduccionDocumento > 0 && <p>Traducción Documento: {traduccionDocumento}$</p>}
            {regenciaChecked && <p>Precio Hora de Regencia: {(horasRegencia * 115)}$</p>}
            {asesoriaChecked && <p>Precio Hora de Asesoría: {(horasAsesoria * 50)}$</p>}
            <p>Subtotal: {totalPrice}$</p>
            <p>Total: {Math.round((totalPrice * 1.13) / 10) * 10}$</p>
            <BotonCorreo className='buttonCorreo' buttonText='Cotizar servicio'></BotonCorreo>
            </div>
          </div>
        )}

        {/* Popup del aviso */}
      {showDisclaimer && (
          <div className="disclaimer-popup">
            <div className="disclaimer-content">
              <h3>Aviso importante</h3>
              <p>
                Los precios que se muestran en esta calculadora son aproximados y pueden ser modificados por diversos factores, como descuentos para PYMEs, descuentos por volumen, o asesoría personalizada.
                Para obtener una cotización más precisa y ajustada a tus necesidades, te recomendamos que te pongas en contacto con nosotros.
              </p>
              <button className="button_upload" onClick={handleDisclaimerClick}>Cerrar</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Upload;
