// Importa los módulos necesarios
import React, { useState, useEffect, useRef } from "react";
import Navbar from './Components/NavBarLite'
import Boton from './Components/Boton'
import "../css/nosotros.css"
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";

import Person from "./Components/Nosotros/person.jsx";
import Hector from "./img/hector.JPG";
import Bayron from "./img/bayron.jpg";

// Definición del componente Home
function Home() {
  useEffect(() => {
    document.body.classList.add("body-nosotros");
    return () => {
      document.body.classList.remove("body-nosotros");
    };
  }, []);
  return (
    <div>
      <Helmet>
        <title>Volkan</title>
        <meta name="description" content="¿Como puedo registrar mis productos quimicos en costa rica?" />
      </Helmet>
      <Navbar />
      <div className="cuerpo">
        <div className="MVV">
            <h1>Misión</h1>
            <p>Brindar soluciones químicas confiables y eficientes que aseguren el cumplimiento de normativas y contribuyan al desarrollo de productos seguros y de calidad.</p>
        </div>
        <div className="MVV">
            <h1>Visión</h1>
            <p>Ser el referente en servicios profesionales en química, reconocido por nuestra excelencia técnica y compromiso con la seguridad.</p>
        </div>
        <div className="MVV">
            <h1>Valores</h1>
            <p>Calidad, compromiso, responsabilidad, transparencia y ética profesional.</p>
        </div>
        <div className="MVV">
            <h1>Compromisos</h1>
            <p class="texto-compromiso">
            <strong>•	Con nuestros clientes:</strong> Nos comprometemos a ofrecer soluciones de alta calidad, ágiles y transparentes, garantizando que cada proceso cumpla con sus expectativas y necesidades.
          </p>
        </div>
        <div className="MVV">
          <p class="texto-compromiso">
            <strong>•	Con nosotros mismos:</strong> Nos comprometemos a seguir creciendo y adaptándonos, manteniendo siempre nuestra resiliencia y pasión por brindar un servicio excepcional.
          </p>

        </div>
        <div className="MVV">
            <h1>Un poco de nosotros</h1>
            <p>Somos un equipo familiar apasionado por la química y la innovación, formado por tres hermanos: Bayron, Héctor y Génesis. Nos entusiasma colaborar con la industria para ofrecer soluciones efectivas a sus problemas y agilizar los trámites necesarios para el cumplimiento de normativas. Con una alta resiliencia y un compromiso inquebrantable, estamos felices de poder ser parte de los procesos que ayudan a las empresas a optimizar su gestión y garantizar la calidad en sus operaciones. Juntos, aportamos experiencia en química, automatización y finanzas para ofrecer un servicio de confianza.</p>
        </div>
      </div>

      <Person 
          name="Bayron Leiva Gamboa"
          position=" Encargado de la gestión y calidad"
          description="Químico graduado de la Universidad de Costa Rica, especializado en el control de calidad y análisis de productos químicos. Con amplia experiencia en proyectos de investigación y gestión de normativas, lidero la parte técnica y química del negocio, asegurando que todos nuestros servicios cumplan con los estándares."
          image={Bayron}
          customClass="Bayron"
        />

      <Person 
          name="Héctor Leiva Gamboa"
          position="Futuro Encargado de Automatización"
          description="Estudiante de Mecatrónica en el Instituto Tecnológico de Costa Rica (TEC). Actualmente en formación, su enfoque en la automatización permitirá optimizar los sistemas de gestión en las empresas, facilitando la implementación de soluciones tecnológicas que mejorarán la eficiencia operativa de nuestros clientes."
          image={Hector}
        />

      <Boton></Boton>
    </div>
  );
}

// Exporta el componente Home
export default Home;
