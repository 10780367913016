import React, { useState } from 'react';
import './InfoComponent.css'; // Archivo CSS para estilos

const InfoComponent = ({ title, description }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleInfo = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`info-container ${isExpanded ? 'expanded' : ''}`} onClick={toggleInfo}>
      <h1 className="info-title">{title}</h1> {/* Renderiza el título pasado como prop */}
      <div className={`info-details ${isExpanded ? 'show' : ''}`}>
        {description.map((item, index) => (
          <div key={index}>
            <h2 className="info-subtitle">{item.subtitle}</h2> {/* Renderiza el subtítulo */}
            <p>{item.text}</p> {/* Renderiza el texto correspondiente */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfoComponent;
