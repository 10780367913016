import { auth } from "../firebase/credenciales.js";
import { signInWithEmailAndPassword } from "firebase/auth";

export default async function loginWithEmailAndPassword(email, password) {
  try {
    const user = await signInWithEmailAndPassword(auth, email, password);
    console.log(user);
    return user;
  } catch (error) {
    console.error("Error al iniciar sesión:", error.message);
    // Puedes personalizar el mensaje de error según tus necesidades
    throw new Error("Error al iniciar sesión. Verifica tus credenciales.");
  }
}
