import React, { useState, useEffect } from "react";
import { auth } from "../../firebase/credenciales"; // Asegúrate de importar la instancia de autenticación desde tu archivo firebase.js
import NavbarPro from "./Components/NavBarPro";
import Navbar from "./Components/NavBarLite";
import "../css/servicios.css";
import { Link, useNavigate  } from "react-router-dom";
import Boton from './Components/Boton'

import { ReactComponent as PngAlimentos } from "./icon/svgalimentacion.svg"; // Importa el SVG como componente
import { ReactComponent as Pnghigenicos } from "./icon/svglimpi.svg"; // Importa el SVG como componente
import { ReactComponent as Pngpesticidas } from "./icon/svgpeste1.svg"; // Importa el SVG como componente
import { ReactComponent as Pngquimicos } from "./icon/svgquimica1.svg"; // Importa el SVG como componente
import { ReactComponent as Pngcosmetico } from "./icon/svgcosmeticos.svg"; // Importa el SVG como componente
import { ReactComponent as Pngdocument } from './icon/newIcon/Regencia.svg'; // Importa el SVG como componente
import { ReactComponent as PngBPM } from './icon/newIcon/BMP.svg'; // Importa el SVG como componente

const ServicioCard = ({ to, svg: SvgIcon, title }) => {
  const navigate = useNavigate(); // Usa el hook useNavigate

  const handleClick = () => {
    navigate(to, { state: { from: '/servicios' } }); // Navega y pasa el estado
  };

  return (
    <div className="box-servicio" onClick={handleClick}>
      <SvgIcon className="svg-Servicio" />
      <p>{title}</p>
    </div>
  );
};

const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setUser(authUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="body-services">
      {user ? <NavbarPro /> : <Navbar />}
      <div className="contenedor-servicio">
        <ServicioCard
          to="/BPM"
          svg={PngBPM}
          title="Buenas Prácticas de Manufactura (BPM)"
        />
        <ServicioCard
          to="/productosquimicos"
          svg={Pngquimicos}
          title="Registro de productos químicos"
        />
        <ServicioCard
          to="/productoshigienicos"
          svg={Pnghigenicos}
          title="Registro de productos higiénicos"
        />
        <ServicioCard
          to="/productosplaguicidas"
          svg={Pngpesticidas}
          title="Registro de plaguicidas"
        />
        <ServicioCard
          to="/productosalimentos"
          svg={PngAlimentos}
          title="Registro de alimentos"
        />
        <ServicioCard
          to="/productoscosmeticos"
          svg={Pngcosmetico}
          title="Registro de cosméticos"
        />
        <ServicioCard
          to="/regencia"
          svg={Pngdocument}
          title="Regencia"
          state={{ from: '/servicios' }}
        />
      </div>
      <Boton />
    </div>
  );
};


export default App;
