import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';

const enviarDatosAFirestore = async (producto, userEmail, totalPrice, nombreEmpresa) => {
  try {
    const db = getFirestore();
    const productosCollection = collection(db, 'productos');

    // Agrega el documento a la colección "productos" con el ID proporcionado
    await addDoc(productosCollection, {
      nombreProducto: producto,
      correoActual: userEmail,
      nombreEmpresa: nombreEmpresa,  // Agrega el nombre de la empresa
      Tramite: 0,  // Ajusta según tus necesidades
      RS: '-',    // Ajusta según tus necesidades
      Status: 'No Pagado',  // Ajusta según tus necesidades
      Fecha: '',  // Usa serverTimestamp para la fecha actual
      dolares: totalPrice,
    });

    console.log('Datos enviados a Firestore correctamente');
  } catch (error) {
    console.error('Error al enviar datos a Firestore:', error);
  }
};

export default enviarDatosAFirestore;
